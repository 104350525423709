import React from "react"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import AddCircle from "@material-ui/icons/AddCircle"
import WatchLaterIcon from "@material-ui/icons/WatchLater"
import ErrorIcon from "@material-ui/icons/Error"
import {theme} from "@indebted/theme"

function TransactionIcon({type}) {
	const map = {
		PaymentSubmitted: (
			<WatchLaterIcon fontSize="small" style={{color: theme.palette.grey.A200, marginRight: theme.spacing(2)}} />
		),
		PaymentFailed: (
			<ErrorIcon fontSize="small" style={{color: theme.palette.error.light, marginRight: theme.spacing(2)}} />
		),
		PaymentSucceeded: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		PaymentPlanChargeSubmitted: (
			<WatchLaterIcon fontSize="small" style={{color: theme.palette.grey.A200, marginRight: theme.spacing(2)}} />
		),
		PaymentPlanChargeSucceeded: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		PaymentPlanChargeFailed: (
			<ErrorIcon fontSize="small" style={{color: theme.palette.error.light, marginRight: theme.spacing(2)}} />
		),
		PaymentPlanSettlementSubmitted: (
			<WatchLaterIcon fontSize="small" style={{color: theme.palette.grey.A200, marginRight: theme.spacing(2)}} />
		),
		PaymentPlanSettlementSucceeded: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		PaymentPlanSettlementFailed: (
			<ErrorIcon fontSize="small" style={{color: theme.palette.error.light, marginRight: theme.spacing(2)}} />
		),
		PaymentPlanDiscountApplied: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		AccountChargebackReceivedThroughClient: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		AccountChargedByClient: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		AccountCompensatedByClient: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		AccountPaymentReceivedThroughClient: (
			<CheckCircleIcon
				fontSize="small"
				style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}}
			/>
		),
		AccountCreated: (
			<AddCircle fontSize="small" style={{color: theme.palette.success.light, marginRight: theme.spacing(2)}} />
		),
	}

	return map[type] || null
}

export {TransactionIcon}
